import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import '../styles/Payment.css';

const PaymentSuccess = () => {
  // Obtener los parámetros de la URL actual
  const location = useLocation();

  useEffect(() => {
    // Crear una función para extraer los parámetros de la URL
    const getParams = () => {
      const searchParams = new URLSearchParams(location.search);
      const citaId = searchParams.get('citaId');
      const consultorId = searchParams.get('consultorId');
      return { citaId, consultorId };
    };

    // Extraer los parámetros
    const { citaId, consultorId } = getParams();

    // Redirigir al deep link de la aplicación
    if (citaId && consultorId) {
      // Esperar unos segundos para mostrar un mensaje antes de redirigir
      setTimeout(() => {
        window.location.href = `myapp://cita-agendada?citaId=${citaId}&consultorId=${consultorId}`;
      }, 5500); // 5 segundos de espera
    }
  }, [location]);

  return (
    <div style={{ textAlign: 'center', marginTop: '10%' }}>
      <h1 className="home_h1" >Estamos verificando tu pago</h1>
      <p className="payment_p_principal" >En cuanto todo esté en orden, te confirmaremos tu cita y el servicio quedará agendado.</p>
      <div style={{ width: '300px', margin: '0 auto', paddingTop:"10%" }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require('./assets/loading.json'),
          }}
          height={300}
          width={300}
        />
      </div>
    </div>
  );
};

export default PaymentSuccess;
